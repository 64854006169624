import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I2FAResponse } from '@core/models/2fa-response.model';
import { IChangePassword } from '@core/models/change-password.model';
import { ICredentials } from '@core/models/credentials.model';
import { IEmailSendCodeResponse } from '@core/models/email-send-code-response.model';
import { ITwoFactorCredentials } from '@core/models/two-factor-credendial-model';
import { UserInfo } from '@core/models/user-info.model';
import { IUser } from '@core/models/user.model';
import { IVerifyConfirmationEmailCode } from '@core/models/verify-confirmation-email-code.model';
import { environment } from '@env/environment';
import { IApiResponse } from '@shared/models/api-response-model';
import { IApiSendModel } from '@shared/models/api-send-model';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  accessTokenFlag = 'access_token'
  refreshTokenFlag = 'refresh_token'
  expiresTokenFlag = 'token-expires-in'
  twoFactorInfo!: { client_id: string, email: string }
  userInfo!: UserInfo | {}
  token = ''
  refreshToken = ''
  tokenExpiresIn: number = 0
  isUserLoginSig = signal<boolean>(false)
  refreshInterval: any
  constructor(public http: HttpClient, private router: Router, private route: ActivatedRoute,) { }

  isUserLogin(): boolean {
    return this.isUserLoginSig()
  }

  async login(credentials: ICredentials): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const obj: IApiSendModel<ICredentials> = {
        "context": "string",
        "data": credentials
      }

      this.http.post<IApiResponse<IUser>>(environment.apiEndpoint + 'api/idp/user/accounts/login', obj).subscribe({
        next: (response) => {
          if (response.data) {
            if (response.data.access_token !== null) {
              this.isUserLoginSig.set(true)
              this.setInfoAfterLogin(response.data);
              resolve(true)
            }
            else if (response.data['access_token'] == null) {
              this.twoFactorInfo = {
                client_id: response.data.client_id,
                email: credentials.email.trim()
              }

              let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
              this.router.navigate(['/auth/security-verification'], { queryParams: { returnUrl: returnUrl } });
            }
          }
        },
        error: () => {
          reject(false)
        }
      })
    })
  }
  async twoFactorLogin(credentials: ITwoFactorCredentials): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const obj: IApiSendModel<ITwoFactorCredentials> = {
        "context": "string",
        "data": credentials
      }

      this.http.post<IApiResponse<I2FAResponse>>(environment.apiEndpoint + 'api/idp/user/accounts/login/2fa', obj).subscribe({
        next: (response) => {
          if (response.data) {
            const user_response = response.data
            const user: IUser = {
              access_token: user_response.accessToken,
              client_id: credentials.clientId,
              expires_in: user_response.expiresIn,
              refresh_token: user_response.refreshToken,
              roles: user_response.roles,
              token_type: user_response.tokenType,
              otp_type: 0
            }

            this.setInfoAfterLogin(user);
            this.isUserLoginSig.set(true)
            resolve(true)
            return
          }

          this.isUserLoginSig.set(false)
          reject(false)
        },
        error: () => {
          this.isUserLoginSig.set(false)
          reject(false)
        }
      })
    })

  }

  async setInfoAfterLogin(response: IUser) {
    this.tokenExpiresIn = response.expires_in
    this.refreshTokenAndSetNewToken()
    this.setLoginSession(response);
    return await this.getUserInformation();
  }

  sendConfirmationCodeByEmail(sendConfirmationCodeObj: { email: string }): Observable<IEmailSendCodeResponse | undefined> {

    const obj: IApiSendModel<{ email: string }> = {
      "context": "string",
      "data": sendConfirmationCodeObj
    }

    return this.http.post<IApiResponse<IEmailSendCodeResponse>>(environment.apiEndpoint + 'api/idp/user/accounts/password/forget/email/sendcode', obj)
      .pipe(map(res => {
        return res.data;
      }));
  }

  verifyConfirmationCodeByEmail(verifyConfirmationObj: IVerifyConfirmationEmailCode): Observable<{ hashCode: string } | undefined> {


    const obj: IApiSendModel<IVerifyConfirmationEmailCode> = {
      "context": "string",
      "data": verifyConfirmationObj
    }

    return this.http.post<IApiResponse<{ hashCode: string }>>(environment.apiEndpoint + 'api/idp/user/accounts/password/forget/email/verifycode', obj)
      .pipe(map(res => {
        return res.data;
      }));
  }

  changePassword(changePasswordObj: IChangePassword): Observable<IApiResponse<{}>> {

    const obj: IApiSendModel<IChangePassword> = {
      "context": "string",
      "data": changePasswordObj
    }

    return this.http.post<IApiResponse<{}>>(environment.apiEndpoint + 'api/idp/user/accounts/password/forget/email/changepassword', obj);
  }

  logout() {

    const obj: IApiSendModel<null> = {
      "context": "string",
      "data": null
    }

    this.http.post(environment.apiEndpoint + 'api/idp/user/accounts/logout', obj).subscribe({
      next: () => {
        this.isUserLoginSig.set(false)
        this.clearTokens()
      }
    })
  }


  getLoginSessionAndCheckToken(): void {
    this.token = localStorage.getItem(this.accessTokenFlag) ?? ''
    this.refreshToken = localStorage.getItem(this.refreshTokenFlag) ?? ''
    this.tokenExpiresIn = +(localStorage.getItem(this.expiresTokenFlag) ?? Date.now())
    if (this.token && this.refreshToken)
      this.verifyToken()
  }

  clearTokens() {
    this.isUserLoginSig.set(false)
    localStorage.removeItem(this.accessTokenFlag)
    localStorage.removeItem(this.refreshTokenFlag)
    localStorage.removeItem(this.expiresTokenFlag)
    this.token = '';
    this.refreshToken = '';
    this.tokenExpiresIn = Date.now();
    this.refreshInterval && clearInterval(this.refreshInterval)
    this.router.navigate(['auth'])

  }

  private setLoginSession(response: IUser): void {
    this.token = response.access_token;
    this.refreshToken = response.refresh_token;
    localStorage.setItem(this.accessTokenFlag, response.access_token)
    localStorage.setItem(this.refreshTokenFlag, response.refresh_token)
    localStorage.setItem(this.expiresTokenFlag, response.expires_in.toString())
  }

  private getUserInformation() {
    this.http.get<IApiResponse<UserInfo>>(environment.apiEndpoint + 'api/v2/Profile/Info').subscribe({
      next: (response) => {
        this.userInfo = response.data || {};
      }
    })
  }

  private verifyToken() {
    this.isUserLoginSig.set(false)
    this.http.post(environment.apiEndpoint + 'api/idp/token/authorize', {}).pipe(take(1)).subscribe({
      next: () => {
        this.isUserLoginSig.set(true)
      },
      error: (error) => {
        this.isUserLoginSig.set(false)
        this.clearTokens()
      }
    })
  }

  private isTokenExpired(expiresAt: number): boolean {
    const currentTime = new Date();
    const expire = new Date(expiresAt - 70000)
    return currentTime >= expire
  }


  private getNewToken() {
    const obj: IApiSendModel<{ refreshToken: string }> = {
      "context": "string",
      "data": { refreshToken: this.refreshToken }
    }
    // this.http.post<IApiResponse<IUser>>(environment.apiEndpoint + 'api/idp/user/accounts/refreshtoken', obj).pipe(take(1)).subscribe({
    //   next: (response) => {
    //     if (response && response.data) {
    //       this.setInfoAfterLogin(response.data)

    //       return
    //     }

    //     if (response) {
    //       this.clearTokens()
    //     }
    //   },
    //   error: (error) => {
    //   }
    // })
  }

  private checkToken() {
    if (this.isTokenExpired(this.tokenExpiresIn)) {
      this.getNewToken()
    }
  }

  private refreshTokenAndSetNewToken() {
    clearInterval(this.refreshInterval)
    this.refreshInterval = setInterval(() => {
      this.checkToken()

    }, 70000);
  }


}
